import * as React from 'react'
import { Global } from '@emotion/react'
import facepaint from 'facepaint'
import { Helmet } from 'react-helmet'
import Text from '../Text'

const mq = facepaint([
  '@media(min-width: 800px)',
  '@media(min-width: 1120px)'
])

const globalStyles = {
  'html, body, #___gatsby, #gatsby-focus-wrapper': {
    height: '100%'
  }
}

const wrapperStyles = mq({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column'
})

const pageStyles = mq({
  display: 'flex',
  flex: 1
})

const containerStyles = mq({
  display: 'flex',
  margin: 'auto',

  justifyContent: 'center',
  flexWrap: 'wrap',
  maxWidth: ['100%', 'auto', 'auto'],
  minWidth: ['100%', 'auto', 'auto'],
  padding: [32, 0, 0]
})

const footerStyles = mq({
  display: 'flex',
  flex: 0,
  justifyContent: 'space-around'
})

const Layout = ({ pageTitle, children, footer }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="apple-itunes-app" content="app-id=UCVZ5WXS8M.com.wrkvbs.workvibes"></meta>
        <title>{pageTitle}</title>
      </Helmet>

      <Global styles={globalStyles} />

      <div css={wrapperStyles}>
        <main css={pageStyles}>
          <div css={containerStyles}>
            {children}
          </div>
        </main>
        {footer && (
          <footer css={footerStyles}>
            {footer}
          </footer>
        )}
      </div>

    </>
  )
}
export default Layout
