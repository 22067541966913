import * as React from 'react'
import { useTheme } from '@emotion/react'

const textStyles = ({ colors, size }) => ({
  color: colors.text,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: size === 'small' ? '1.2rem' : '1.5rem',
  lineHeight: size === 'small' ? '1.7rem' : '2.4rem',
  letterSpacing: '-0.02em',
  textAlign: 'center'
})

const Text = (props) => {
  const theme = useTheme()
  return (
    <p style={textStyles({ ...theme, ...props })} {...props}>
      {props.children}
    </p>
  )
}

export default Text
